import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
// import Tile from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OSM from "ol/source/OSM";
import Split from "split.js";
import Feature from "ol/Feature.js";
import Geolocation from "ol/Geolocation.js";
import Point from "ol/geom/Point.js";
import {fromLonLat} from 'ol/proj.js';
import WMTS, { optionsFromCapabilities } from "ol/source/WMTS.js";
import WMTSCapabilities from "ol/format/WMTSCapabilities.js";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style.js";
import { Vector as VectorSource } from "ol/source.js";
import GPX from "ol/format/GPX.js";

function el(id) {
  return document.getElementById(id);
}

let map;
const view = new View({
  center: [2622959.0,5896268.4],
  zoom: 16,
});

const labelStyle = new Style({
    text: new Text({
        font: '12px Calibri,sans-serif',
        overflow: true,
      textAlign: 'left',
       fill: new Fill({
            color: '#000',
          }),
        stroke: new Stroke({
            color: '#fff',
            width: 3,
          }),
      }),
  });

const positionStyle =   new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: "#FF0000",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2,
      }),
    }),
  });

const mainStyle = [positionStyle, labelStyle];

const trkSources= new VectorSource();

setInterval(() => {
  fetch("https://backend.orientrack.run/updates")
  // fetch("https://backend.orientrack.run:55581/updates" )
  .then(function(response) {
   return  response.text()
    })
  .then(function(responseText){
    // console.log(responseText);
    if (responseText.length <= 10) return;

    const responseJson = JSON.parse(responseText);
    // console.log(responseJson);

    for (let key_id in responseJson) {
        // console.log(key_id + ": " + responseJson[key_id].display_name);

      let id=responseJson[key_id].id;
      let lat=responseJson[key_id].lat;
      let lon=responseJson[key_id].lon;
      let name=responseJson[key_id].display_name;
      const info="id: " + id + ", name: " + name + ", lat: " + lat + ", lon: " + lon;
      // console.log("lat: " + lat +", lon: "+ lon);
      document.getElementById('positions').innerHTML = info;
      let pointcoordinate = fromLonLat([lon, lat]);
      let pointtrk = new Point(pointcoordinate);

      const foundFeature = trkSources.getFeatureById(id);
      if (foundFeature) {
        foundFeature.setGeometry(pointtrk);

        if (foundFeature.getProperties()["name"] != name) { // update name property
          foundFeature.setProperties({name: name, id: id});
        }
      }
      else {
        const positionFeature = new Feature();
        positionFeature.setGeometry(pointtrk);
        positionFeature.setProperties({name: name, id : id});
        positionFeature.setId(id)

        trkSources.addFeature(positionFeature);     
        view.setCenter(pointcoordinate); // center map on new tracker
      }
    }
  } )
}, 1000);

Split(["#split-0", "#split-1"], {
  sizes: [20, 75],
  maxSize: [500, Infinity],
});

const parser = new WMTSCapabilities();

const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
  // tracking: false, // enable/ disable own location tracking
});

el("track").addEventListener("change", function () {
  geolocation.setTracking(this.checked);
});

// update the HTML page when the position changes.
geolocation.on("change", function () {
  el("accuracy").innerText = geolocation.getAccuracy() + " [m]";
  el("altitude").innerText = geolocation.getAltitude() + " [m]";
  el("altitudeAccuracy").innerText = geolocation.getAltitudeAccuracy() + " [m]";
  el("heading").innerText = geolocation.getHeading() + " [rad]";
  el("speed").innerText = geolocation.getSpeed() + " [m/s]";
});

// handle geolocation error.
geolocation.on("error", function (error) {
  const info = document.getElementById("info");
  info.innerHTML = error.message;
  info.style.display = "";
});

const accuracyFeature = new Feature();
geolocation.on("change:accuracyGeometry", function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: "#3399CC",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2,
      }),
    }),
  }),
);

geolocation.on("change:position", function () {
  const coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
});

const style = {
  Point: new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: "rgba(255,255,0,0.4)",
      }),
      radius: 5,
      stroke: new Stroke({
        color: "#ff0",
        width: 1,
      }),
    }),
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: "#f00",
      width: 3,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: "#0f0",
      width: 3,
    }),
  }),
};

const gpx_vector = new VectorLayer({
  source: new VectorSource({
    url: "/activity_8932688941_mtb_hoja_2022.gpx",
    format: new GPX(),
  }),
  style: function (feature) {
    return style[feature.getGeometry().getType()];
  },
});


// fetch('https://szallo.duckdns.org:55581/static/WMTSCapabilities.xml')
fetch("/WMTSCapabilities.xml")
  .then(function (response) {
    return response.text();
  })
  .then(function (text) {
    const result = parser.read(text);
    const options_sztana = optionsFromCapabilities(result, {
      layer: "sztana",
      matrixSet: "EPSG:3857",
    });
    const options_afrika = optionsFromCapabilities(result, {
      layer: "afrika",
      matrixSet: "EPSG:3857",
    });

    map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
          opacity: 0.7,
        }),
        // new Tile({
        //   source: new OSM({
        //     url: "https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png",
        //   }),
        // }),
        new TileLayer({
          opacity: 1,
          source: new WMTS(options_sztana),
        }),
        new TileLayer({
          opacity: 1,
          source: new WMTS(options_afrika),
        }),
        new VectorLayer({
          map: map,
          source: new VectorSource({
            features: [accuracyFeature, positionFeature],
          }),
        }),
        new VectorLayer({
          map: map,
          source: trkSources,
          style: function (feature) {
            const label = feature.getProperties()["name"];
            labelStyle.getText().setText(label);

            const id = feature.getProperties()["id"];
            const color = "#" + id.substr(0, 6);
            positionStyle.getImage().getFill().setColor(color);
            return mainStyle;
          },
        }),
        gpx_vector,
      ],
      target: "map",
      view: view,
    });
 });
